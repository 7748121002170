<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="d-flex w-full align-center">
        <h5 class="">
          <span v-if="fullAccess">Todas Solicitações</span>
          <span v-else>Minhas Solicitações</span>
        </h5>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addServiceRequest" small>
          <v-icon left>mdi-plus</v-icon>
          Nova Solicitação
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table :items="requestList" :headers="headers">
          <template v-slot:item.status="{ item }">
            <v-chip :color="statusColors[item.status] || 'grey'" small outlined label>
              {{ statusTexts[item.status] || item.status }}
            </v-chip>
          </template>
          <template v-slot:item.deadline="{ item }">
            {{ item.deadline | date }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="fullAccess || item.requiredById === user.id"
              small
              :to="{ name: 'service.details', params: { id: item.id } }"
              icon
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <ServiceRequestModal @success="refresh" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceRequestModal from '@/components/services/modal/ServiceRequestModal.vue'

export default {
  name: 'App',
  components: { ServiceRequestModal },
  data: () => ({
    headers: [
      { text: 'Servico', value: 'Service.name' },
      { text: 'Descrição', value: 'description' },
      { text: 'Prazo', value: 'deadline' },
      { text: 'Solicitado por', value: 'RequiredBy.name' },
      { text: 'Responsável', value: 'Responsible.name' },
      { text: 'Status', value: 'status' },
      { text: 'Ações', value: 'actions' },
    ],
    statusColors: {
      declined: 'orange',
      accepted: 'success',
      pending: 'blue',
    },
    statusTexts: {
      accepted: 'Aceito',
      pending: 'Pendente',
      declined: 'Em Andamento',
    },
  }),
  methods: {
    addServiceRequest() {
      this.$root.$emit('service-request')
    },
    refresh() {
      this.$emit('refresh')
    },
  },
  computed: {
    ...mapGetters('auth', ['user', 'hasPermission']),
    fullAccess() {
      return this.hasPermission(1073741824)
    },
    requestList() {
      return this.requests.filter(({ requiredById }) => this.fullAccess || requiredById === this.user.id)
    },
  },
  props: {
    loading: Boolean,
    requests: Array,
  },
}
</script>

<style>
/* Add your styles here */
</style>
