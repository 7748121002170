<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="d-flex w-full align-center">
        <h5>Solicitações para mim</h5>
      </v-card-title>
      <v-card-text>
        <v-data-table :items="requestList" :headers="headers">
          <template v-slot:item.deadline="{ item }">
            {{ item.deadline | date }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="statusColors[item.status] || 'grey'" small outlined label>
              {{ statusTexts[item.status] || item.status }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small :to="{ name: 'service.details', params: { id: item.id } }" icon>
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    headers: [
      { text: 'Servico', value: 'Service.name' },
      { text: 'Descrição', value: 'description' },
      { text: 'Prazo', value: 'deadline' },
      { text: 'Solicitado por', value: 'RequiredBy.name' },
      { text: 'Responsável', value: 'Responsible.name' },
      { text: 'Status', value: 'status' },
      { text: 'Ações', value: 'actions' },
    ],
    statusColors: {
      declined: 'orange',
      accepted: 'success',
      pending: 'blue',
    },
    statusTexts: {
      accepted: 'Aceito',
      pending: 'Pendente',
      declined: 'Em Andamento',
    },
  }),
  methods: {},
  computed: {
    ...mapGetters('auth', ['user']),
    requestList() {
      return this.requests.filter(({ responsibleId }) => responsibleId === this.user.id)
    },
  },
  props: {
    loading: Boolean,
    requests: Array,
  },
}
</script>

<style>
/* Add your styles here */
</style>
