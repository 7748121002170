<template>
  <div class="d-flex flex-column gap-4">
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <ServicesRequest
      v-if="hasPermission([1073741824, 2147483648])"
      :loading="loading"
      :requests="requests"
      @refresh="getRequests"
    />
    <ServicesRequestForMe
      v-if="hasPermission(4294967296)"
      :loading="loading"
      :requests="requests"
      @refresh="getRequests"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ServicesRequest from '@/components/services/ServicesRequest.vue'
import ServicesRequestForMe from '@/components/services/ServicesRequestForMe.vue'
import SERVICES from '@/services/services'

export default {
  name: 'Services',
  data() {
    return {
      requests: [],
      loading: false,
      error: null,
    }
  },
  methods: {
    async getRequests() {
      try {
        this.loading = true
        this.error = null
        const { requests } = await SERVICES.request.getAll()
        this.requests = requests
      } catch (error) {
        this.error = error.message || error
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.getRequests()
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  components: { ServicesRequest, ServicesRequestForMe },
}
</script>
